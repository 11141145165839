import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import PermissionGuard from './components/PermissionGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';

import { Route, Routes } from 'react-router-dom';
import XeroSettingsPage from './pages/management/XeroSettingsPage';
import XeroAuthRedirectPage from './pages/management/XeroAuthRedirectPage';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const CircuitDetailsPage = Loadable(lazy(() => import('./pages/management/CircuitPage')));
const CustomerListPage = Loadable(lazy(() => import('./pages/management/CustomerListPage')));
const CustomerDetailsPage = Loadable(lazy(() => import('./pages/management/CustomerDetailsPage')));
const CustomerInvoiceDetailsPage = Loadable(lazy(() => import('./pages/management/invoicing/CustomerInvoiceDetailsPage')));
const CreateCustomerInvoicePage = Loadable(lazy(() => import('./pages/management/invoicing/CreateCustomerInvoicePage')));
const ManageOrderDetailsPage = Loadable(lazy(() => import('./pages/management/ManageOrderDetailsPage')));
const QuickCircuitShipmentDetailsPage = Loadable(lazy(() => import('./pages/quickcircuit/QuickCircuitShipmentDetailsPage')));
const DeviceContractDetailsPage = Loadable(lazy(() => import('./pages/management/DeviceContractDetailsPage')));
const PropertyListPage = Loadable(lazy(() => import('./pages/management/PropertyListPage')));
const OrganisationListPage = Loadable(lazy(() => import('./pages/management/OrganisationListPage')));
const OrganisationDetailsPage = Loadable(lazy(() => import('./pages/management/OrganisationDetailsPage')));
const InstallationDetailsPage = Loadable(lazy(() => import('./pages/management/InstallationDetailsPage')));
const InstallationLocationDetailsPage = Loadable(lazy(() => import('./pages/management/InstallationLocationDetailsPage')));
const RoomLocationDetailsPage = Loadable(lazy(() => import('./pages/management/RoomLocationPage')));
const DeviceListPage = Loadable(lazy(() => import('./pages/management/DeviceListPage')));
const DeviceDetailsPage = Loadable(lazy(() => import('./pages/management/DeviceDetailsPage')));
const InventoryPage = Loadable(lazy(() => import('./pages/management/InventoryPage')));
const CustomerInvoiceSearchPage = Loadable(lazy(() => import('./pages/management/CustomerInvoiceSearchPage')));
const CustomerOrderListPage = Loadable(lazy(() => import('./pages/quickcircuit/CustomerOrderListPage')));
const ReportListPage = Loadable(lazy(() => import('./pages/tools/ReportListPage')));
const DeviceReconReportPage = Loadable(lazy(() => import('./pages/tools/DeviceReconReportPage')));
const DeviceOperationsPage = Loadable(lazy(() => import('./pages/tools/DeviceOperationsPage')));
const BillingPage = Loadable(lazy(() => import('./pages/management/BillingPage')));
const InvoicingPage = Loadable(lazy(() => import('./pages/management/InvoicingPage')));
const CustomerOrderDetailsPage = Loadable(lazy(() => import('./pages/quickcircuit/CustomerOrderDetailsPage')));
const AccessKeyListPage = Loadable(lazy(() => import('./pages/management/AccessKeyListPage')));
const AccessKeyDetailsPage = Loadable(lazy(() => import('./pages/management/AccessKeyDetailsPage')));
const AccessRoleListPage = Loadable(lazy(() => import('./pages/management/AccessRoleListPage')));
const AccessRoleDetailsPage = Loadable(lazy(() => import('./pages/management/AccessRoleDetailsPage')));
const GuestGuard = Loadable(lazy(() => import('./components/GuestGuard')));
const UserListPage = Loadable(lazy(() => import('./pages/management/UserListPage')));
const UserDetailsPage = Loadable(lazy(() => import('./pages/management/UserDetailsPage')));
const PriceSetListPage = Loadable(lazy(() => import('./pages/management/PriceSetListPage')));
const PriceSetDetailsPage = Loadable(lazy(() => import('./pages/management/PriceSetDetailsPage')));
const InsightHighlightVoteListPage = Loadable(lazy(() => import('./pages/feedback/InsightHighlightVoteListPage')));
const InsightHighlightVoteDetailsPage = Loadable(lazy(() => import('./pages/feedback/InsightHighlightVoteDetailsPage')));


const Overview = Loadable(lazy(() => import('./pages/dashboard/OverviewPage')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequiredPage')));
const NotFound = Loadable(lazy(() => import('./pages/NotFoundPage')));
const ServerError = Loadable(lazy(() => import('./pages/ServerErrorPage')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/LoginPage')));
const Logout = Loadable(lazy(() => import('./pages/authentication/LogoutPage')));


const Router = () => (
  <Routes>
    <Route path="/">
      <Route element={<AuthGuard><DashboardLayout /></AuthGuard>}>
        <Route path="authentication">
          <Route path="login" element={<GuestGuard><Login /></GuestGuard>} />
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route index element={<PermissionGuard required="SEARCH_CUSTOMER" fallbackRoute="orders"><Overview /></PermissionGuard>} />
        <Route path="customers">
          <Route index element={<PermissionGuard required="SEARCH_CUSTOMER"><CustomerListPage /></PermissionGuard>} />
          <Route path=":customerId">
            <Route index element={<PermissionGuard required="GET_CUSTOMER_BY_ID"><CustomerDetailsPage /></PermissionGuard>} />
            <Route path="invoices/:invoiceId" element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><CustomerInvoiceDetailsPage /></PermissionGuard>} />
            <Route path="invoices/create" element={<PermissionGuard required="CREATE_CUSTOMER_INVOICE"><CreateCustomerInvoicePage /></PermissionGuard>} />
            <Route path="orders/:orderId" element={<PermissionGuard required="SEARCH_CUSTOMER_ORDER"><ManageOrderDetailsPage /></PermissionGuard>} />
            <Route path="orders/:orderId/invoices/:invoiceId" element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><CustomerInvoiceDetailsPage /></PermissionGuard>} />
            <Route path="orders/shipments/:shipmentId" element={<PermissionGuard required="SEARCH_QUICKCIRCUIT_SHIPMENT"><QuickCircuitShipmentDetailsPage /></PermissionGuard>} />
            <Route path="contracts/:contractId" element={<PermissionGuard required="SEARCH_DEVICE_CONTRACT"><DeviceContractDetailsPage /></PermissionGuard>} />
            <Route path="contracts/:contractId/invoices/:invoiceId" element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><CustomerInvoiceDetailsPage /></PermissionGuard>} />
            <Route path="orders/:orderId/contracts/:contractId" element={<PermissionGuard required="SEARCH_DEVICE_CONTRACT"><DeviceContractDetailsPage /></PermissionGuard>} />
            <Route path="orders/:orderId/contracts/:contractId/invoices/:invoiceId" element={<PermissionGuard required="SEARCH_DEVICE_CONTRACT"><CustomerInvoiceDetailsPage /></PermissionGuard>} />
          </Route>
        </Route>
        <Route path="users">
          <Route index element={<PermissionGuard required="SEARCH_USER"><UserListPage /></PermissionGuard>} />
          <Route path=":userId">
            <Route index element={<PermissionGuard required="GET_USER_BY_ID"><UserDetailsPage /></PermissionGuard>} />
          </Route>
        </Route>
        <Route path="devices">
          <Route index element={<PermissionGuard required="SEARCH_DEVICE"><DeviceListPage /></PermissionGuard>} />
          <Route path=":deviceId" element={<PermissionGuard required="GET_DEVICE_BY_ID"><DeviceDetailsPage /></PermissionGuard>} />
        </Route>
        <Route path="organisations">
          <Route index element={<PermissionGuard required="SEARCH_ORGANISATION"><OrganisationListPage /></PermissionGuard>} />
          <Route path=":organisationId">
            <Route index element={<PermissionGuard required="GET_ORGANISATION_BY_ID"><OrganisationDetailsPage /></PermissionGuard>} />
            <Route path="installations/:installationId">
              <Route index element={<PermissionGuard required="GET_INSTALLATION_BY_ID"><InstallationDetailsPage /></PermissionGuard>} />
              <Route path="installationlocations/:installationLocationId" element={<PermissionGuard required="GET_INSTALLATIONLOCATION_BY_ID"><InstallationLocationDetailsPage /></PermissionGuard>} />
              <Route path="roomlocations/:roomLocationId" element={<PermissionGuard required="GET_INSTALLATIONLOCATION_BY_ID"><RoomLocationDetailsPage /></PermissionGuard>} />
              <Route path="circuits/:circuitId" element={<PermissionGuard required="GET_INSTALLATIONLOCATION_BY_ID"><CircuitDetailsPage /></PermissionGuard>} />
            </Route>
          </Route>
        </Route>
        <Route path="properties">
          <Route index element={<PermissionGuard required="SEARCH_INSTALLATION"><PropertyListPage /></PermissionGuard>} />
        </Route>
        <Route path="inventory">
          <Route index element={<PermissionGuard required="SEARCH_DEVICE_INVENTORY_LOG"><InventoryPage /></PermissionGuard>} />
        </Route>
        <Route path="invoicesearch">
          <Route index element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><CustomerInvoiceSearchPage /></PermissionGuard>} />
          <Route path="invoices/:invoiceId" element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><CustomerInvoiceDetailsPage /></PermissionGuard>} />
        </Route>
        <Route path="orders">
          <Route index element={<PermissionGuard required="SEARCH_DEVICE_CONTRACT"><CustomerOrderListPage isQuickCircuit={false} /></PermissionGuard>} />
        </Route>
        <Route path="pricesets">
          <Route index element={<PermissionGuard required="SEARCH_PRICE_SET"><PriceSetListPage /></PermissionGuard>} />
          <Route path=":priceSetId" element={<PermissionGuard required="GET_PRICE_SET"><PriceSetDetailsPage /></PermissionGuard>} />
        </Route>

        {/* Tools */}
        <Route path="tools">
          <Route path="reports">
            <Route index element={<PermissionGuard required="SEARCH_REPORT"><ReportListPage /></PermissionGuard>} />
            <Route path="devicerecon/:reportId" element={<PermissionGuard required="GET_REPORT"><DeviceReconReportPage /></PermissionGuard>} />
          </Route>
          <Route path="device-operations">
            <Route index element={<PermissionGuard required="CREATE_DEVICE"><DeviceOperationsPage /></PermissionGuard>} />
          </Route>
          <Route path="device-contracts">
            <Route index element={<PermissionGuard required="SEARCH_DEVICE_CONTRACT"><BillingPage /></PermissionGuard>} />
          </Route>
          <Route path="invoicing">
            <Route index element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><InvoicingPage /></PermissionGuard>} />
            <Route path="invoices/:invoiceId" element={<PermissionGuard required="SEARCH_CUSTOMER_INVOICE"><CustomerInvoiceDetailsPage /></PermissionGuard>} />
          </Route>
          <Route path="xero-settings">
            <Route index element={<PermissionGuard required="UPDATE_XERO_TENANT"><XeroSettingsPage /></PermissionGuard>} />
            <Route path="auth-redirect" element={<PermissionGuard required="UPDATE_XERO_TENANT"><XeroAuthRedirectPage /></PermissionGuard>} />
          </Route>
        </Route>
        {/* Quick Circuit */}
        <Route path="quickcircuit">
          <Route path="orders" element={<PermissionGuard required="SEARCH_CUSTOMER_ORDER"><CustomerOrderListPage isQuickCircuit /></PermissionGuard>} />
          <Route path="orders/:orderId" element={<PermissionGuard required="SEARCH_CUSTOMER_ORDER"><CustomerOrderDetailsPage isQuickCircuit /></PermissionGuard>} />
          <Route path="shipments/:shipmentId" element={<PermissionGuard required="SEARCH_QUICKCIRCUIT_SHIPMENT"><QuickCircuitShipmentDetailsPage /></PermissionGuard>} />
        </Route>
        {/* Feedback */}
        <Route path="feedback">
          <Route path="insight-highlight-votes" element={<PermissionGuard required="SEARCH_INSIGHTHIGHLIGHTVOTES"><InsightHighlightVoteListPage /></PermissionGuard>} />
          <Route path="insight-highlight-votes/:propertyId/:insightHighlightId" element={<PermissionGuard required="SEARCH_INSIGHTHIGHLIGHTVOTES"><InsightHighlightVoteDetailsPage /></PermissionGuard>} />
        </Route>
        {/* User */}
        <Route path="accesskeys">
          <Route index element={<PermissionGuard required="SEARCH_ACCESS_KEY"><AccessKeyListPage /></PermissionGuard>} />
          <Route path=":accessKeyId" element={<PermissionGuard required="SEARCH_ACCESS_KEY"><AccessKeyDetailsPage /></PermissionGuard>} />
        </Route>
        <Route path="accessroles">
          <Route index element={<PermissionGuard required="SEARCH_ACCESS_KEY"><AccessRoleListPage /></PermissionGuard>} />
          <Route path=":accessRoleId" element={<PermissionGuard required="SEARCH_ACCESS_KEY"><AccessRoleDetailsPage /></PermissionGuard>} />
        </Route>
      </Route>
    </Route>
    <Route path="401" element={<AuthorizationRequired />} />
    <Route path="404" element={<NotFound />} />
    <Route path="500" element={<ServerError />} />
    <Route path="*" element={<NotFound />} />
  </Routes >
);

export default Router;
