import { useMutation, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { CustomerOrder, CustomerOrderStatus } from 'src/types/CustomerOrder';
import { DeviceContractType } from 'src/types/DeviceContract';
import { CustomerOrderItem } from 'src/types/CustomerOrderItem';
import { useAggregatedQueries } from 'src/hooks/useAggregatedQuery';
import { get, post, put } from '../utils';
import { getCustomerOrderItems } from './customer-order-item';
import useLog from 'src/hooks/useLog';
import useCustomerOrderData from 'src/hooks/useCustomerOrderData';

export function getCustomerOrderById(orderId: string): Promise<CustomerOrder> {
  return get<CustomerOrder>(`customerorder/${orderId}`);
}

export function getCustomerOrders(props: {
  status?: CustomerOrderStatus;
  customerId?: string;
  textQuery?: string;
}): Promise<CustomerOrder[]> {
  const { status, customerId, textQuery } = props;
  const params = {
    status: status && status.toString(),
    customerId,
    textQuery,
  };
  return get<CustomerOrder[]>('customerorder', params);
}

export function createCustomerOrder(
  customerId: string,
  uniqueOrderReference?: string,
  details?: string,
  status?: string,
  notes?: string,
  bundledSubscriptionMonths?: number
): Promise<CustomerOrder> {
  const body = {
    customerId,
    uniqueOrderReference,
    details,
    status,
    notes,
    bundledSubscriptionMonths,
  };
  return post<CustomerOrder>('customerorder', body);
}

export function sendOrderReminderToQuickCircuit(orderId: string): Promise<void> {
  return post<void>(`CustomerOrder/${orderId}/reminder`, {});
}

export function sendOrderNotificationToQuickCircuit(orderId: string): Promise<void> {
  return post<void>(`CustomerOrder/${orderId}/orderNotification`, {});
}

interface GenerateDeviceContractOptions {
  startsAt?: string;
  expiresAt?: string;
  contractType?: DeviceContractType;
  bundledSubscriptionMonths?: number;
}

export function generateDeviceContractsFromOrderId(orderId: string, options?: GenerateDeviceContractOptions): Promise<void> {
  return post<void>(`CustomerOrder/${orderId}/generateDeviceContracts`, {
    ...options,
  });
}

export function updateCustomerOrder(orderId: string, properties: object) {
  const body = {
    id: orderId,
    ...properties,
  };
  return put<CustomerOrder>(`customerorder/${orderId}`, body);
}

export function useSendCustomerOrderAcknowledgementEmailMutation() {
  const log = useLog();
  const { updateOrder } = useCustomerOrderData();

  return useMutation(({orderId}: { orderId: string }) => {
    return post<CustomerOrder>(`customerorder/${orderId}/orderAcknowledgement`);
  }, {
    onSuccess: (updatedOrder) => {
      updateOrder(updatedOrder);
      log.success('Order Acknowledgement Email successfully sent.');
    },
    onError: () => {
      log.success('Failed to send Order Acknowledgement Email.');
    }
  });
}

export function useQueryCustomerOrders(
  props: {
    status?: CustomerOrderStatus;
    customerId?: string;
    textQuery?: string;
  },
  options?: UseQueryOptions<CustomerOrder[], Error>
): UseQueryResult<CustomerOrder[]> {
  return useQuery(['CustomerOrder', props.status, props.customerId, props.textQuery], () => getCustomerOrders(props), options);
}

export function useQueryCustomerOrderDeviceCounts(orderIds: string[]) {
  return useAggregatedQueries<CustomerOrderItem[], Error, { id: string; count: number }>(
    orderIds.map((orderId) => ({
      queryKey: ['CustomerOrderDeviceCount', orderId],
      queryFn: () => getCustomerOrderItems(orderId),
      select: (data: CustomerOrderItem[]) => ({
        id: orderId,
        count: data.filter((item) => !item.sku.startsWith("SUB")).length,
      }),
    }))
  );
}

export function useQueryCustomerOrderById(orderId: string): UseQueryResult<CustomerOrder> {
  return useQuery(['CustomerOrder', orderId], () => getCustomerOrderById(orderId));
}

export function useQueryCustomerOrdersById(orderIds: string[]) {
  return useAggregatedQueries<CustomerOrder>(
    orderIds.map((orderId) => ({
      queryKey: ['CustomerOrder', orderId],
      queryFn: () => getCustomerOrderById(orderId),
    }))
  );
}
