import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Circuit } from 'src/types/Circuit';
import { DeviceWithLastSample } from 'src/types/DeviceWithLastSample';
import { Outside } from 'src/types/Outside';
import { Property } from 'src/types/Property';
import { Room, RoomLocation } from 'src/types/Room';
import { get } from '../utils';
import { getPropertyInfo } from './legacy-routes';

enum QueryKeys {
  Property = 'Property',
}

export async function searchRooms(query: Partial<Room>): Promise<Room[]> {
  return get('/room', query);
}
export async function getRoomById(id: string): Promise<Room> {
  return get(`/room/${id}`);
}

export async function getPropertyById(id: string): Promise<Property> {
  return get(`/property/${id}`);
}

export async function searchProperties(query: Partial<Pick<Property, 'address1' | 'customPropertyId' | 'organisationId'>>): Promise<Property[]> {
  return get('/property', query);
}

export function useSearchProperties(query: Partial<Pick<Property, 'address1' | 'customPropertyId' | 'organisationId'>>) {
  return useQuery<Property[], AxiosError>([QueryKeys.Property, query], () => searchProperties(query));
}

export type InstallationLocationParentSearchResults = {
  type: 'OUTSIDE' | 'ROOMLOCATION' | 'CIRCUIT';
  roomLocation?: RoomLocation;
  circuit?: Circuit;
  outside?: Outside;
};

export async function searchByInstallationLocationIdForParent(
  installationLocationId: string
): Promise<InstallationLocationParentSearchResults> {
  const query = { installationLocationId };

  const [roomLocations, circuits, outsides] = await Promise.all([
    get('/roomlocation', query),
    get('/circuit', query),
    get('/outside', query),
  ]);

  if (roomLocations.length > 0) {
    return {
      type: 'ROOMLOCATION',
      roomLocation: roomLocations[0],
    };
  }
  if (circuits.length > 0) {
    return {
      type: 'CIRCUIT',
      circuit: circuits[0],
    };
  }
  if (outsides.length > 0) {
    return {
      type: 'ROOMLOCATION',
      outside: outsides[0],
    };
  }

  throw new Error('Not found');
}

export async function getDeviceForRoomLocation(propertyId: string, roomLocationId: string): Promise<DeviceWithLastSample> {
  const propInfo = await getPropertyInfo(propertyId);
  const roomLocation = propInfo.rooms.flatMap((r) => r.roomLocations).find((rl) => rl.id === roomLocationId);
  return roomLocation.device;
}

export async function getRoomLocationById(id): Promise<RoomLocation> {
  const res = await get<RoomLocation[]>('roomLocation/', {
    id,
  });

  console.log({ res });
  if (res.length) {
    return res[0];
  }

  return null;
}
